import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'
import Contact from './contact'

import '../assets/scss/main.scss'

const getScrollPosition = () => {
    let supportPageOffset = window.pageXOffset !== undefined
    let isCSS1Compat = (document.compatMode || '') === 'CSS1Compat'
    let scroll = {
        x: supportPageOffset
            ? window.pageXOffset
            : isCSS1Compat
                ? document.documentElement.scrollLeft
                : document.body.scrollLeft,
        y: supportPageOffset
            ? window.pageYOffset
            : isCSS1Compat
                ? document.documentElement.scrollTop
                : document.body.scrollTop,
    }
    return scroll
}

class Layout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isMenuVisible: false,
            loading: 'is-loading',
            onTopStyle: {},
        }

        this.handleScroll = this.handleScroll.bind(this)
        if (typeof window !== `undefined`) {
            window.addEventListener('scroll', this.handleScroll)
        }
    }

    componentDidMount() {
        this.timeoutId = setTimeout(() => {
            this.setState({ loading: '' })
        }, 100)
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId)
        }
    }

    handleScroll() {
        const scroll = getScrollPosition()
        if (scroll.y === 0) {
            this.setState({
                onTopStyle: {
                    backgroundColor: 'transparent',
                },
            })
        } else {
            this.setState({
                onTopStyle: {
                    backgroundColor: 'black',
                },
            })
        }
    }

    render() {
        const { children } = this.props

        return (
            <StaticQuery
                query={graphql`
                    query SiteTitleQuery {
                        site {
                            siteMetadata {
                                title
                            }
                        }
                        headerImage: file(relativePath: { eq: "logo.png" }) {
                            childImageSharp {
                                resolutions(width: 600, height: 200) {
                                    ...GatsbyImageSharpResolutions
                                }
                            }
                        }
                    }
                `}
                render={data => (
                    <>
                        <Helmet
                            title={data.site.siteMetadata.title}
                            meta={[
                                { name: 'description', content: 'Sample' },
                                {
                                    name: 'keywords',
                                    content: 'sample, something',
                                },
                                {
                                    property: 'fb:pages',
                                    content: '1809018032716769',
                                },
                                {
                                    property: 'fb:app_id',
                                    content: '192060041404665',
                                },
                                { property: 'og:url', content: '' },
                                { property: 'og:type', content: '' },
                                {
                                    property: 'og:title',
                                    content: data.site.siteMetadata.title,
                                },
                                { property: 'og:description', content: '' },
                                { property: 'og:image', content: '' },
                                {
                                    name: 'twitter:card',
                                    content: 'summary_large_image',
                                },
                                {
                                    name: 'twitter:creator',
                                    content: 'Synaphea',
                                },
                                {
                                    name: 'twitter:title',
                                    content: data.site.siteMetadata.title,
                                },
                                { name: 'twitter:description', content: '' },
                                { name: 'twitter:image', content: '' },
                            ]}
                        >
                            <html lang="en" />
                        </Helmet>
                        <div
                            className={`body ${this.state.loading} ${
                                this.state.isMenuVisible
                                    ? 'is-menu-visible'
                                    : ''
                            }`}
                        >
                            <div id="wrapper">
                                <Header
                                    onTopStyle={this.state.onTopStyle}
                                    siteTitle={data.site.headerImage}
                                />
                                {children}
                                <Contact />
                                <Footer />
                            </div>
                        </div>
                    </>
                )}
            />
        )
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
