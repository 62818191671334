import React, { Component } from 'react'

import Logo from '../assets/images/logo.png'

class Header extends Component {
    handleClick(e) {
        this.checkbox.checked = false
    }

    render() {
        return (
            <header id="header" style={this.props.onTopStyle}>
                <div className="logo">
                    <img
                        src={Logo}
                        alt="Synaphea logo"
                        style={{ height: '55px' }}
                    />
                </div>
                <input
                    className="menu-btn"
                    type="checkbox"
                    id="menu-btn"
                    ref={input => {
                        this.checkbox = input
                    }}
                />
                <label className="menu-icon" htmlFor={'menu-btn'}>
                    <span className="navicon" />
                </label>
                <ul className="menu">
                    <li>
                        <a
                            className="menu-link"
                            href="/#Home"
                            onClick={this.handleClick.bind(this)}
                        >
                            Home
                        </a>
                    </li>
                    <li>
                        <a
                            className="menu-link"
                            href="/#EasyAccess"
                            onClick={this.handleClick.bind(this)}
                        >
                            What we do
                        </a>
                    </li>
                    <li>
                        <a
                            className="menu-link"
                            href="/#Team"
                            onClick={this.handleClick.bind(this)}
                        >
                            Team
                        </a>
                    </li>
                    <li>
                        <a
                            className="menu-link"
                            href="/#Contact"
                            onClick={this.handleClick.bind(this)}
                        >
                            Contact
                        </a>
                    </li>
                </ul>
            </header>
        )
    }
}

export default Header
