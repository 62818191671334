import React from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'

const Contact = props => (
    <ScrollableAnchor id={'Contact'}>
        <section id="contact">
            <div className="inner">
                <section>
                    <form method="post" action="/sayHello">
                        <div className="field half first">
                            <label htmlFor="name">Name</label>
                            <input type="text" name="name" id="name" />
                        </div>
                        <div className="field half">
                            <label htmlFor="email">Email</label>
                            <input type="text" name="email" id="email" />
                        </div>
                        <div className="field">
                            <label htmlFor="message">Message</label>
                            <textarea name="message" id="message" rows="6" />
                        </div>
                        <ul className="actions">
                            <li>
                                <input
                                    type="submit"
                                    value="Send Message"
                                    className="special"
                                />
                            </li>
                            <li>
                                <input type="reset" value="Clear" />
                            </li>
                        </ul>
                    </form>
                </section>
                <section className="split">
                    <section>
                        <div className="contact-method">
                            <span className="icon alt fa-envelope" />
                            <h3>Email</h3>
                            <a href="mailto:info@synaphea.com">
                                info@synaphea.com
                            </a>
                        </div>
                    </section>
                    <section>
                        <div className="contact-method">
                            <span className="icon alt fa-phone" />
                            <h3>Phone</h3>
                            <span>+(30) 698 326 3935</span>
                        </div>
                    </section>
                    <section>
                        {/* <div className="contact-method">
                            <span className="icon alt fa-home"></span>
                            <h3>Address</h3>
                            <span>74 Pireos<br />
                            18346 Attiki<br />
                            Greece</span>
                        </div> */}
                    </section>
                </section>
            </div>
        </section>
    </ScrollableAnchor>
)

export default Contact
