import React from 'react'

import Social from './social'

const Footer = props => (
    <footer id="footer">
        <div className="inner">
            <Social />
            <ul className="copyright">
                <li>&copy; Synaphea</li>
            </ul>
        </div>
    </footer>
)

export default Footer
